export const resources = {
  en: {
    translation: {
      adress:'2 Saralanji, Tsaghkadzor city',
      home: "Home",
      contact: "Contact",
      news: "News",
      gallery: "Gallery",
      about: "About",
      photos: "Photos",
      readMore: "Read more",
      phoneNumber: "Phone Number",
      address: "Address",
      email: "Email",
      sendMessage: "Send Massage",
      rightsReserved: "All Rights Reserved.",
      home1: "Home Page One",
      home2: "Home Page Two",
      features: "Features",
      blog: "Blog",
      shop: "Shop",
      shopDetails: "Shop Details",
      cardPage: "Card Page",
      mr: "Mr",
      mrs: "Mrs",
      others: "Others",
      firstname: "Firstname",
      surname: "Surname",
      birth: "Date Of Birth",
      countryResidence: "Country Of Residence:",
      city: "City",
      provency: "Provency",
      postalCode: "Postal Code",
      getStartedNow: "Get Started Now",
      isMember: "Are you a member?",
      login: "Login",
      or: "or",
      withSocial: "Register With Social Media",
      product: "Product",
      price: "Price",
      quantity: "Quantity",
      total: "Total",
      edit: "Edit",
      apply: "Apply",
      updateCard: "Update Card",
      proceedCheckout: "Proceed to Checkout",
      calcShippings: "Calculate Shipping",
      updateTotal: "Update Total",
      cardTotal: "Card Total",
      cardSubTotal: "Card Subtotal",
      shippingAndHandling: "Shipping and Handling",
      freeShipping: "Free Shipping",
      orderTotal: "Order Total",
      casino: "Casino",
      days: "Days",
      hour: "Hour",
      minutes: "Minutes",
      seconds: "Seconds",
      registerToday: "REGISTER TODAY",
      depositCash: "DEPOSIT CASH",
      startGambling: "START GAMBLING",
      bannersList: {
        1: {
          titleh3: "Banner 1",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia. The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains. People gather here for excitement and relaxation. The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
        2: {
          titleh3: "Banner 2",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia. The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains. People gather here for excitement and relaxation. The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
        3: {
          titleh3: "Banner 3",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia. The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains. People gather here for excitement and relaxation. The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
        4: {
          titleh3: "Banner 4",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia. The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains. People gather here for excitement and relaxation. The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
      },
      welcomeToCasino: "Welcome To Casino",
      aboutSubTitle:
        "At Modeltheme, we show only the best websites and portfolios built completely with passion simplicity and creativity!",

      frequentylAskQUestion: "FREQUENTLY ASK QUESTION",
      frequentylAskQUestionSubTitle:
        "At Modeltheme we show only the best websites and portfolios built completely with passion simplicity and creativity !",
      fotterText:
        "Upropriate brand economca sound technolog after covalent technology enable prospective wastng markets whereas propriate and brand economca sound technolog",
      topJackpotGames: "Top games",
      ourNewsletter: "Contact Me",
      casinoesports:
        "Casino esports organization supported by community leaders",
        findUsOn:'Find Us On'
    },
  },
  ru: {
    translation: {
      adress:'г. Цахкадзор, ул. Сараланджи. 2',
      home: "Главный",
      contact: "Контакты",
      news: "Новости",
      gallery: "Галерея",
      photos: "Фото",
      about: "О Нас",
      sendMessage: "Отправить письмо",
      ourNewsletter: "СВЯЖИТЕСЬ СО МНОЙ",
      topJackpotGames: "Топ игр",


      bannersList: {
        1: {
          titleh3: "Banner 1",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R          ",
          desc: "Одно из лучших игорных заведений Армении. азино расположено в горнолыжном коротном городе Цахкадзор (Армения), в окружении гор. Здесь собираются люди ради азарта и отдыха. Казино предлагает развлечение как для любителей, так и для настоящих ценителей игровой индустрии.",
          btnText: "join us today",
        },
        2: {
          titleh3: "Banner 2",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia.The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains.People gather here for excitement and relaxation.The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
        3: {
          titleh3: "Banner 3",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia.The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains.People gather here for excitement and relaxation.The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
        4: {
          titleh3: "Banner 4",
          titleh2: "Genuine Money Transaction",
          titleh1: "Casino Astoria.R",
          desc: "One of the best gambling establishments in Armenia.The casino is located in the ski resort town of Tsakhkadzor (Armenia), surrounded by mountains.People gather here for excitement and relaxation.The casino offers entertainment for both amateurs and true connoisseurs of the gaming industry.",
          btnText: "join us today",
        },
      },
      findUsOn:'Найдите нас'
    },
  },
  arm: {
    translation: {
      sendMessage: "Ուղղարկել նամակ",
      adress:'ք.Ծաղկաձոր , Սարալանջի փ. 2',
      home: "Գլխավոր",
      contact: "Կոնտակտներ",
      news: "Նորություններ",
      gallery: "Նկարներ",
      photos: "Նկարներ",
      about: "Մեր մասին",
      bannersList: {
        1: {
          titleh3: "Բաններ 1",
          titleh2: "Իրական փողի գործարք",
          titleh1: "Կազինո Astoria.R",
          desc: "Հայաստանում լավագույն խաղատներից մեկը։ Խաղատունը գտնվում է Ծաղկաձոր քաղաքում ՝ շրջապատված բարձր լեռներով։ Մենք հյուրընկալում ենք ազարտի և հանգիստը վայելելու իսկական սիրահարներին։",
          btnText: "միացե՛ք մեզ",
        },
        2: {
          titleh3: "Բաններ 2",
          titleh2: "Իրական փողի գործարք",
          titleh1: "Կազինո Astoria.R",
          desc: "Խաղատունը գտնվում է Ծաղկաձոր քաղաքում ՝ շրջապատված բարձր լեռներով։",
          btnText: "միացե՛ք մեզ",
        },
        3: {
          titleh3: "Բաններ 3",
          titleh2: "Իրական փողի գործարք",
          titleh1: "Կազինո Astoria.R",
          desc: "",
          btnText: "միացե՛ք մեզ",
        },
        4: {
          titleh3: "Բաններ 4",
          titleh2: "Իրական փողի գործարք",
          titleh1: "Կազինո Astoria.R",
          desc: "Մենք հյուրընկալում ենք ազարտի և հանգիստը վայելելու իսկական սիրահարներին։",
          btnText: "միացե՛ք մեզ",
        },
      },
      welcomeToCasino: "ASTORIA.R",
      aboutSubTitle: "ժամանցի ժամանակից  կենտրոն",
      registerToday: "ԳՐԱՆՑՎԵՔ ԱՅՍՕՐ",
      depositCash: "ԱՎԱՆԴԻ ԿԱՆԽԻԿ",
      startGambling: "ՍԿՍԵՔ ԽԱՂԱԼ",

      registerTodaySubtitle:
        "Հատկանշականորեն տրամադրեք մուտք դեպի mutfuncto օգտվողներին, մինչդեռ հաղորդակցվեք լծակային ծառայություններին",
      depositCashSubtitle:
        "Հատկանշականորեն տրամադրեք մուտք դեպի mutfuncto օգտվողներին, մինչդեռ հաղորդակցվեք լծակային ծառայություններին",
      startGamblingSubtitle:
        "Հատկանշականորեն տրամադրեք մուտք դեպի mutfuncto օգտվողներին, մինչդեռ հաղորդակցվեք լծակային ծառայություններին",

      frequentylAskQUestion: "ՀԱՃԱԽ ՏՐՎՈՂ ՀԱՐՑԵՐ",
      frequentylAskQUestionSubTitle:
        "Modeltheme-ում մենք ցույց ենք տալիս միայն լավագույն կայքերն ու պորտֆոլիոները, որոնք ստեղծվել են ամբողջությամբ կրքով, պարզությամբ և ստեղծագործականությամբ:",

      phoneNumber: "Հեռ․ Համար",
      address: "Հասցե",
      email: "Էլ Հասցե",
      footerText:
        "Կովալենտային տեխնոլոգիայից հետո համապատասխան ապրանքանիշի economca ձայնային տեխնոլոգիան հնարավորություն է տալիս հեռանկարային թափոնների շուկաներին, մինչդեռ պատշաճ և բրենդային economca ձայնային տեխնոլոգիան",
      topJackpotGames: "Լավագույն խաղեր",
      ourNewsletter: "Կապ ինձ հետ",
      casinoesports:
        "Կազինո esports կազմակերպություն համայնքի ղեկավարների աջակցությամբ",
        findUsOn:'Գտեք մեզ'
    },
  },
  ir: {
    translation: {
      home: "صفحه اصلی",
      contact: "مخاطب",
      news: "اخبار",
      gallery: "وبلاگ",
      photos: "در باره",
      about: "عکس ها",
    },
  },
};
