import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import RatingTwo from "../sidebar/rating-2";

const blogList = [
  {
    imgUrl: "assets/images/footer/01.jpg",
    imgAlt: "footer-widget-img",
    title: "Russian Poker",
    name: "Poker",
    price: "$230",
  },
  {
    imgUrl: "assets/images/footer/02.jpg",
    imgAlt: "footer-widget-img",
    title: "Black Jack",
    name: "Poker",
    price: "$230",
  },
  {
    imgUrl: "assets/images/footer/03.jpg",
    imgAlt: "footer-widget-img",
    title: "ROULETTE",
    name: "Poker",
    price: "$230",
  },
];

const Footer = () => {
  const { t, i18n } = useTranslation();

  const desc = t("findUsOn");
  const blogTitle = t("topJackpotGames");
  const newsTitle = t("ourNewsletter");
  const newsDesc = t("casinoesports");

  const socialList = [
   
    {
      imgUrl: "https://geospatialmedia.s3.amazonaws.com/wp-content/uploads/2016/06/Google-420x420.jpeg",
      imgAlt: "social",
      siteLink: "https://www.google.com/maps/dir//astoria+casino/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x4040379cf2672f3d:0x376ca2ae26454bf2?sa=X&ved=2ahUKEwiUgoqW0-SEAxWjBNsEHdGbBZkQ9Rd6BAg4EAA",
    },
    {
      imgUrl: "https://www.sendible.com/hs-fs/hubfs/blog-import/2020/20-08-Aug/sm-icons-facebook-logo.png?width=180&name=sm-icons-facebook-logo.png",
      imgAlt: "social",
      siteLink: "https://www.facebook.com/",
    },
    {
      imgUrl: "https://upload.wikimedia.org/wikipedia/commons/9/95/Instagram_logo_2022.svg",
      imgAlt: "social",
      siteLink: "https://www.instagram.com/",
    },
  ];

  const infoList = useMemo(() => {
    return [
      {
        imgUrl: "assets/images/footer/icons/01.png",
        imgAlt: "icon",
        text: `+37494966669`,
      },
      {
        imgUrl: "assets/images/footer/icons/02.png",
        imgAlt: "icon",
        text: `info@astoriar.am`,
      },
      {
        imgUrl: "assets/images/footer/icons/03.png",
        imgAlt: "icon",
        text: t('adress'),
      },
    ];
  }, []);

  return (
    <footer
      className="footer-section"
      style={{ backgroundImage: "url(/assets/images/footer/bg3.jpg)" }}
    >
      <div className="footer-top">
        <div className="container">
          <div className="row g-3 justify-content-center g-lg-0">
            {infoList.map((val, i) => (
              <div className="col-lg-4 col-sm-6 col-12" key={i}>
                <div className="footer-top-item lab-item">
                  <div className="lab-inner">
                    <div className="lab-thumb">
                      <img src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                    </div>
                    <div className="lab-content">
                      <span>{val.text}</span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="footer-middle padding-top padding-bottom">
        <div className="container">
          <div className="row padding-lg-top">
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-lg-0">
                  <div className="fm-item-title mb-4">
                    <img src="assets/images/logo/logo.png" alt="logo" />
                  </div>
                  <div className="fm-item-content">
                    <p className="mb-4">{desc}</p>
                    <ul className="match-social-list d-flex flex-wrap align-items-center">
                      {socialList.map((val, i) => (
                        <li key={i}>
                          <a target="blank" href={val.siteLink}>
                            <img style={{borderRadius:10}} height={50} width={ 50} src={`${val.imgUrl}`} alt={`${val.imgAlt}`} />
                          </a>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item mb-lg-0">
                  <div className="fm-item-title">
                    <h4>{blogTitle}</h4>
                  </div>
                  <div className="fm-item-content">
                    {blogList.map((val, i) => (
                      <div className="fm-item-widget lab-item" key={i}>
                        <div className="lab-inner">
                          <div className="lab-thumb">
                            <Link to="/">
                              {" "}
                              <img
                                src={`${val.imgUrl}`}
                                alt={`${val.imgAlt}`}
                                className="rounded-3"
                              />
                            </Link>
                          </div>
                          <div className="lab-content">
                            <Link to="/">
                              <h6>{val.title}</h6>
                            </Link>
                            <p>
                              {val.name}: <b>{val.price}</b>
                            </p>
                            <RatingTwo />
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-12">
              <div className="footer-middle-item-wrapper">
                <div className="footer-middle-item-3 mb-lg-0">
                  <div className="fm-item-title">
                    <h4>{newsTitle}</h4>
                  </div>
                  <div className="fm-item-content">
                    {/* <p>{newsDesc}</p> */}
                    <form>
                      <div className="form-group mb-4">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Your Name"
                        />
                      </div>
                      <div className="form-group mb-2">
                        <input
                          type="email"
                          className="form-control"
                          placeholder="Your Email"
                        />
                      </div>
                      <button type="submit" className="default-button">
                        <span>
                          {t("sendMessage")}{" "}
                          <i className="icofont-circled-right"></i>
                        </span>
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-bottom">
        <div className="container">
          <div className="row">
            <div className="col-12">
              <div className="footer-bottom-content text-center">
                <p>
                  &copy;{new Date().toISOString().slice(0,4)} <Link to="/">{t("casino")}</Link> -{" "}
                  {t("rightsReserved")}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
