import { Fragment, useState } from "react";
import Footer from "../component/layout/footer";
import Header from "../component/layout/header-2";
import PageHeader from "../component/layout/page-header";
import img4 from '../assets/images/blog/4.jpg'
import img5 from '../assets/images/blog/5.jpg'
import img6 from '../assets/images/blog/6.jpg'
import img7 from '../assets/images/blog/7.jpg'
import img8 from '../assets/images/blog/8.jpg'
import img9 from '../assets/images/blog/9.jpg'
import img10 from '../assets/images/blog/10.jpg'
import img11 from '../assets/images/blog/7.jpg'
import img12 from '../assets/images/blog/12.jpg'
import img13 from '../assets/images/blog/13.jpg'
import img14 from '../assets/images/blog/14.jpg'

//vip
import img15 from '../assets/images/blog/vip/1.jpg'
import img16 from '../assets/images/blog/vip/2.jpg'
import img17 from '../assets/images/blog/vip/3.jpg'
import img18 from '../assets/images/blog/vip/4.jpg'
import img19 from '../assets/images/blog/vip/5.jpg'
import img20 from '../assets/images/blog/vip/6.jpg'
import img21 from '../assets/images/blog/vip/7.jpg'
import img22 from '../assets/images/blog/vip/8.jpg'
import img23 from '../assets/images/blog/vip/9.jpg'
import img24 from '../assets/images/blog/vip/11.jpg'
import img25 from '../assets/images/blog/vip/12.jpg'
import img26 from '../assets/images/blog/vip/13.jpg'
import img27 from '../assets/images/blog/vip/14.jpg'
import img28 from '../assets/images/blog/vip/16.jpg'

const collectionDataList = [
  {
    id: 4,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img4,
    link: "#",
    catagory: "main",
  },
  {
    id: 5,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img5,
    link: "#",
    catagory: "main",
  },
  {
    id: 6,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img6,
    link: "#",
    catagory: "main",
  },
  {
    id: 7,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img7,
    link: "#",
    catagory: "main",
  },
  {
    id: 8,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img8,
    link: "#",
    catagory: "main",
  },
  {
    id: 9,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img9,
    link: "#",
    catagory: "main",
  },
  {
    id: 10,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img10,
    link: "#",
    catagory: "main",
  },
  {
    id: 11,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img11,
    link: "#",
    catagory: "main",
  },
  {
    id: 12,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img12,
    link: "#",
    catagory: "main",
  },
  {
    id: 13,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img13,
    link: "#",
    catagory: "main",
  },
  {
    id: 14,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img14,
    link: "#",
    catagory: "main",
  },
  //vip
  {
    id: 15,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img15,
    link: "#",
    catagory: "vip",
  },
  {
    id: 16,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img16,
    link: "#",
    catagory: "vip",
  },
  {
    id: 17,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img17,
    link: "#",
    catagory: "vip",
  },
  {
    id: 18,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img18,
    link: "#",
    catagory: "vip",
  },
  {
    id: 19,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img19,
    link: "#",
    catagory: "vip",
  },
  {
    id: 20,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img20,
    link: "#",
    catagory: "vip",
  },
  {
    id: 21,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img21,
    link: "#",
    catagory: "vip",
  },
  {
    id: 22,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img22,
    link: "#",
    catagory: "vip",
  },
  {
    id: 23,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img23,
    link: "#",
    catagory: "vip",
  },
   {
    id: 24,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img24,
    link: "#",
    catagory: "vip",
  },

   {
    id: 25,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img25,
    link: "#",
    catagory: "vip",
  },

   {
    id: 26,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img26,
    link: "#",
    catagory: "vip",
  },
  {
    id: 27,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img27,
    link: "#",
    catagory: "vip",
  },{
      id: 28,
    title: "free poker games",
    cate: "Catagory: Roulette",
    image: img28,
    link: "#",
    catagory: "vip",
  },

];

const GameListTwo = () => {
  const [items, setItems] = useState(collectionDataList);
  const filterItem = (categItem) => {
    const updateItems = collectionDataList.filter((curElem) => {
      return curElem.catagory === categItem;
    });
    setItems(updateItems);
  };

  return (
    <Fragment>
      <Header />
      {/* <PageHeader title={"Gallery"} curPage={"Gallery"} /> */}
      <section className="match-section padding-top padding-bottom">
        <div className="container">
          <div className="section-wrapper">
            <ul className="match-filter-button-group common-filter-button-group d-flex flex-wrap justify-content-center mb-5 text-uppercase">
            <li onClick={() => filterItem("main")}>Հիմնական</li>
              <li onClick={() => filterItem("vip")}>ՎԻՊ</li>
            </ul>
            <div className="row g-4 match-grid grid">
              {items.map((elem) => {
                const { id, title, cate, image, link } = elem;
                return (
                  <div
                    className="col-lg-4 col-12 matchlistitem main"
                    key={id}
                  >
                    <div className="game__item item-layer">
                      <div className="game__inner text-center p-0">
                        <div className="game__thumb mb-0">
                          <img
                            src={image}
                            alt={title}
                            className="rounded-3 w-100"
                          />
                        </div>
                        <div className="game__overlay">
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </Fragment>
  );
};

export default GameListTwo;
