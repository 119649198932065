import { Fragment } from "react";
import Footer from "../component/layout/footer";
import HeaderTwo from "../component/layout/header-2";
import BannerTwo from "../component/section/banner-2";
import Faq from "../component/section/faq";
import GameListTwo from "./game-list";

const HomeTwo = () => {
  return (
    <Fragment>
      <HeaderTwo />
      <BannerTwo />
      {/* <About /> */}
      {/* <Collection /> */}
       <GameListTwo /> 
      {/* <GameTwo /> */}
      {/* <JaqportTwo />  */}
      {/* <Testimonial /> */}
      {/* <Faq /> */}
      {/* <Footer /> */}
    </Fragment>
  );
};

export default HomeTwo;
